import { unionBy } from "lodash"

const initialState = {
  workplaces: [],
  limit: 8,
  page: 1,
  workplacesSummary: null,
  workPlaceInDashboard: [],
  keyword: "",
  count: null,
}

function WorkplacesReducer(state, action) {
  switch (action.type) {
    case "FETCH_WORKPLACES":
      return {
        ...state,
        workplaces: action.payload,
      }
    case "UPDATE_WORKPLACES_PAGE":
      return {
        ...state,
        page: action.payload,
      }
    case "FETCH_WORKPLACES_SUMMARY":
      return {
        ...state,
        workplacesSummary: action.payload,
      }
    case "FETCH_WORKPLACES_DASHBOARD":
      return {
        ...state,
        workPlaceInDashboard: !action.payload.reset
          ? unionBy(state.workPlaceInDashboard, action.payload.data, "id")
          : action.payload.data,
      }
    case "FETCH_WORKPLACES_DASHBOARD_BY_KEYWORD":
      return {
        ...state,
        workPlaceInDashboard: !action.payload.reset
          ? unionBy(state.workPlaceInDashboard, action.payload.data, "id")
          : action.payload.data,
        keyword: action.payload.keyword,
        count: action.payload.count,
      }
    case "UPDATE_KEYWORD":
      return {
        ...state,
        keyword: action.payload,
      }
    default:
      return state
  }
}

export default { initialState, WorkplacesReducer }
