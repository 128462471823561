import React from "react"
import { useReducer } from "react"
import { useEffect } from "react"
import { navigate } from "gatsby"
import jobs from "./jobs"
import jobsTrack from "./jobsTrack"
import workplaces from "./workplace"
import user from "./user"
import { ThemeProvider } from "baseui"
import theme from "../styles/theme"
import { getProfile } from "../actions/auth"
import CloudApi from "../cloudApi/api"
import workplace from "./workplace"
import dashboard from "./dashboard"

export const Store = React.createContext({})

const StoreProvider = props => {
  const value = {
    jobs: useReducer(jobs.JobsReducer, jobs.initialState),
    jobsTrack: useReducer(jobsTrack.JobsTrackReducer, jobsTrack.initialState),
    user: useReducer(user.UserReducer, user.initialState),
    workplaces: useReducer(
      workplaces.WorkplacesReducer,
      workplace.initialState
    ),
    dashboard: useReducer(dashboard.DashboardReducer, dashboard.initialState),
  }
  const [, dispatch] = value.user
  const [, jobDispatch] = value.jobs
  useEffect(() => {
    if (localStorage.getItem("tokenId")) {
      getProfile(dispatch)
        .then(() => {
          CloudApi.getJobsApplied().then(appliedJob =>
            jobDispatch({
              type: "FETCH_APPLIED_JOBS_DATA",
              payload: appliedJob,
            })
          )
        })
        .catch(() => {
          navigate("/")
        })
    }
  }, [dispatch, jobDispatch])

  return (
    <Store.Provider value={value}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </Store.Provider>
  )
}

export default StoreProvider
