// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-applied-job-js": () => import("../src/pages/applied-job.js" /* webpackChunkName: "component---src-pages-applied-job-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-detail-js": () => import("../src/pages/job-detail.js" /* webpackChunkName: "component---src-pages-job-detail-js" */),
  "component---src-pages-job-form-js": () => import("../src/pages/job-form.js" /* webpackChunkName: "component---src-pages-job-form-js" */),
  "component---src-pages-job-track-js": () => import("../src/pages/job-track.js" /* webpackChunkName: "component---src-pages-job-track-js" */),
  "component---src-pages-login-js": () => import("../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-post-job-js": () => import("../src/pages/post-job.js" /* webpackChunkName: "component---src-pages-post-job-js" */),
  "component---src-pages-profile-js": () => import("../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-user-js": () => import("../src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */),
  "component---src-pages-work-place-js": () => import("../src/pages/work-place.js" /* webpackChunkName: "component---src-pages-work-place-js" */)
}

