import { unionBy } from "lodash"
const initialState = {
  users: [],
  limit: 8,
  userPage: 1,
  studentPage: 1,
  professorPage: 1,
  adminPage: 1,
  studentKeyword: "",
  professorKeyword: "",
  adminKeyword: "",
  activeUserTab: "0",
  students: [],
  professors: [],
  admins: [],
  userSummary: null,
  userKeyword: null,
  appliedJobSummary: null,
  postJobSummary: null,
  mostAppliedJob: null,
  appliedJobStaticYear: null,
  appliedJobStudyYear: null,
  appliedJobStatic: null,
  postedJobStaticYear: null,
  activeStaticYear: null,
  activeStaticFacultyYear: null,
  postedJobStaticFaculty: null,
  postedJobTag: null,
  postedJobLast: null,
  appliedJobStaticFaculty: null,
  professorCount: null,
  adminCount: null,
  activeStudyYear: null,
  appliedJobYear: null,
}

function DashboardReducer(state, action) {
  switch (action.type) {
    case "FETCH_USERS":
      return {
        ...state,
        users: action.payload,
      }
    case "UPDATE_USER_PAGE":
      return {
        ...state,
        userPage: action.payload,
      }
    case "FETCH_SUMMARY_APPLIED_JOB":
      return {
        ...state,
        appliedJobSummary: action.payload,
      }
    case "FETCH_MOST_APPLIED_JOB":
      return {
        ...state,
        mostAppliedJob: action.payload,
      }
    case "FETCH_APPLIED_JOB_STATIC_YEAR":
      return {
        ...state,
        appliedJobStaticYear: {
          ...state.appliedJobStaticYear,
          [action.payload.year]: action.payload.data,
        },
      }
    case "FETCH_POST_JOB_SUMMARY":
      return {
        ...state,
        postJobSummary: action.payload,
      }
    case "FETCH_POSTED_JOB_STATIC_YEAR":
      return {
        ...state,
        postedJobStaticYear: {
          ...state.postedJobStaticYear,
          [action.payload.year]: action.payload.data,
        },
      }
    case "FETCH_POSTED_JOB_LASTEST":
      return {
        ...state,
        postedJobLast: action.payload,
      }
    case "FETCH_POSTED_STATIC_FACULTY":
      return {
        ...state,
        postedJobStaticFaculty: {
          ...state.postedJobStaticFaculty,
          [action.payload.year]: action.payload.data,
        },
      }
    case "FETCH_POSTED_STATIC_TAG":
      return {
        ...state,
        postedJobTag: action.payload,
      }
    case "FETCH_APPLIED_JOB_STUDY_YEAR":
      return {
        ...state,
        appliedJobStudyYear: {
          ...state.appliedJobStudyYear,
          [action.payload.year]: action.payload.data,
        },
      }
    case "FETCH_APPLIED_JOB_STATIC_FACULTY":
      return {
        ...state,
        appliedJobStaticFaculty: action.payload,
      }
    case "SEARCH_USERS":
      return {
        ...state,
        users: action.payload.data,
        userKeyword: action.payload.keyword,
      }
    case "UPDATE_KEYWORD":
      return {
        ...state,
        userKeyword: action.payload,
      }
    case "SET_POSTED_JOB_STATIC_YEAR_OPTIONS":
      return {
        ...state,
        activeStaticYear: action.payload,
      }
    case "SET_POSTED_JOB_STATIC_FACULTY_YEAR":
      return {
        ...state,
        activeStaticFacultyYear: action.payload,
      }
    case "FETCH_USER_SUMMARY":
      return {
        ...state,
        userSummary: action.payload,
      }
    case "UPDATE_USER_TAB":
      return {
        ...state,
        activeUserTab: action.payload,
      }
    case "FETCH_USERS_STUDENT":
      return {
        ...state,
        students: !action.payload.reset
          ? unionBy(action.payload.data, state.students, "id")
          : action.payload.data,
        studentKeyword: action.payload.keyword,
        studentCount: action.payload.count,
      }
    case "FETCH_USERS_PROFESSOR":
      return {
        ...state,
        professors: !action.payload.reset
          ? unionBy(action.payload.data, state.professors, "id")
          : action.payload.data,
        professorKeyword: action.payload.keyword,
        professorCount: action.payload.count,
      }
    case "FETCH_USERS_ADMIN":
      return {
        ...state,
        admins: !action.payload.reset
          ? unionBy(action.payload.data, state.admins, "id")
          : action.payload.data,
        adminKeyword: action.payload.keyword,
        adminCount: action.payload.count,
      }
    case "UPDATE_STUDENT_PAGES":
      return {
        ...state,
        studentPage: action.payload,
      }
    case "UPDATE_PROFESSOR_PAGES":
      return {
        ...state,
        professorPage: action.payload,
      }
    case "UPDATE_ADMIN_PAGES":
      return {
        ...state,
        adminPage: action.payload,
      }
    case "SET_ACTIVE_STUDY_YEAR":
      return {
        ...state,
        activeStudyYear: action.payload,
      }
    case "SET_APPLIED_JOB_YEAR":
      return {
        ...state,
        appliedJobYear: action.payload,
      }
    default:
      return state
  }
}

export default { initialState, DashboardReducer }
