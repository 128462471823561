const initialState = {
	user: null
};

function UserReducer(state, action) {
	switch (action.type) {
		case 'FETCH_USER_DATA':
			return { ...state, user: action.payload };
		case 'CLEAR_USER_DATA':
			return { ...state, user: {} };
		default:
			return state;
	}
}

export default { initialState, UserReducer };
