import unionBy from "lodash/unionBy"

const initialState = {
  jobsTrack: [],
  total: 0,
  unPublishJobs: [],
  unPublishJobTotal: 0,
  usersInJobsTrack: [],
}

function JobsTrackReducer(state, action) {
  switch (action.type) {
    case "FETCH_JOBS_TRACK_DATA":
      return {
        ...state,
        jobsTrack: unionBy(action.payload.jobs, state.jobsTrack, "id").sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        ),
        total: action.payload.total_job,
      }
    case "FETCH_JOBS_TRACK_UN_PUBLISH_DATA":
      return {
        ...state,
        unPublishJobs: unionBy(
          action.payload.jobs,
          state.unPublishJobs,
          "id"
        ).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
        unPublishJobTotal: action.payload.total_job,
      }
    case "ADD_USERS_IN_JOB_TRACK":
      return {
        ...state,
        usersInJobsTrack: unionBy(action.payload, state.usersInJobsTrack, "id"),
      }
    default:
      return state
  }
}

export default { initialState, JobsTrackReducer }
