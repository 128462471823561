import axios from "axios"

const baseURL = process.env.API_URL

export default class Api {
  static request(config) {
    return axios({
      baseURL,
      ...config,
      headers: {
        "Content-Type": "application/json",
        ...config.headers,
      },
    })
      .then(({ data }) => data)
      .catch(({ response, ...other }) => {
        if (response) {
          throw response
        }
        throw new Error(other)
      })
  }

  static createJob(data) {
    return this.request({
      method: "POST",
      url: "/jobs",
      data: { ...data },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getJobLists({ offset = 0 }) {
    return this.request({
      method: "GET",
      url: `/jobs?type=all&offset=${offset}&limit=${6}`,
    })
  }

  static getJobListsByFilter({ filterData, offset = 0 }) {
    return this.request({
      method: "GET",
      url: `/jobs?type=filter&${filterData}&offset=${offset}`,
    })
  }

  static getPostedJob(offset, limit) {
    return this.request({
      method: "GET",
      url: `/jobs/posted?offset=${offset}&limit=${limit || 12}&is_publish=true`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getPostedJobUnPublish(offset, limit) {
    return this.request({
      method: "GET",
      url: `/jobs/posted?offset=${offset}&limit=${limit ||
        12}&is_publish=false`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getTags() {
    return this.request({
      method: "GET",
      url: "/tags",
    })
  }

  static createTag(name) {
    return this.request({
      method: "POST",
      url: "/tags",
      data: {
        name,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getJobsApplied() {
    if (localStorage.getItem("tokenId")) {
      return this.request({
        method: "GET",
        url: "/jobs/applied",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
        },
      })
    }
    return Promise.reject()
  }

  static appliedJob(jobId) {
    return this.request({
      method: "POST",
      url: "/jobs/apply",
      data: { job_id: jobId },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static patchAppliedJobStatus(jobId, type, userId) {
    return this.request({
      method: "PATCH",
      url: `/jobs/${type}-confirm`,
      data: {
        job_id: jobId,
        user_id: userId,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getDepartments() {
    return this.request({
      method: "GET",
      url: "/faculties",
    })
  }

  static getProfile() {
    return this.request({
      method: "GET",
      url: "/auth/profile",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static updatePhoneUser(userId, phone,lineID) {
    return this.request({
      method: "PUT",
      url: "/users/phone",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
      data: {
        id: userId,
        phone,
        line_id:lineID
      },
    })
  }

  static getJobsAppliedUsersList(jobId) {
    return this.request({
      url: `/jobs/applied/users/${jobId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getJobDetail(jobId) {
    return this.request({
      url: `/jobs/detail/${jobId}`,
    })
  }

  static getPrices() {
    return this.request({
      url: `/jobs/price`,
    })
  }

  static editJob(data) {
    return this.request({
      method: "PUT",
      url: "/jobs",
      data: { ...data },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getJobForEdit(jobId) {
    return this.request({
      url: `/jobs/edit/detail/${jobId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getWorkplaces() {
    return this.request({
      url: `/workplaces?limit=100&offset=0`,
    })
  }

  static createWorkPlace(data) {
    return this.request({
      url: `/workplaces`,
      method: "POST",
      data: { ...data },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static updateWorkPlace(data) {
    return this.request({
      url: `/workplaces`,
      method: "PUT",
      data: { ...data },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static deleteWorkPlace(id) {
    return this.request({
      url: `/workplaces/${id}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getUsers() {
    return this.request({
      url: `/dashboard/user/count`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getUserSummary() {
    return this.request({
      url: `/dashboard/user/count`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getSummaryAppliedJob() {
    return this.request({
      url: `/dashboard/appliedjob/count`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getMostAppliedJob() {
    return this.request({
      url: `/dashboard/appliedjob/mostappliedjob?limit=5&offset=0`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getAppliedJobStaticYear(year) {
    return this.request({
      url: `/dashboard/appliedjob/statistic/monthly?year=${year}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getAppliedJobStudyYear(year) {
    return this.request({
      url: `/dashboard/appliedjob/studyyear?year=${year}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  // static getPostedJobStudyYear() {
  //   return this.request({
  //     url: `/dashboard/postedjob/studyyear`,
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
  //     },
  //   })
  // }

  static getPostedJobStaticTag() {
    return this.request({
      url: "/dashboard/postedjob/statistic/tag",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getPostedJobStaticFaculty(year) {
    return this.request({
      url: `/dashboard/postedjob/statistic/faculty?year=${year}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getAppliedJobStaticFaculty() {
    return this.request({
      url: "/dashboard/appliedjob/statistic/faculty",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getPostJobStaticFacultyMonthly() {
    return this.request({
      url: "/dashboard/postedjob/statistic/monthly?year=2563",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getPostJobCount() {
    return this.request({
      url: "/dashboard/postedjob/count",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getPostJobStaticFaculty() {
    return this.request({
      url: "/dashboard/postedjob/statistic/faculty",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getPostedJobStaticYear(year) {
    return this.request({
      url: `/dashboard/postedjob/statistic/monthly?year=${year}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getPostedJobLastest() {
    return this.request({
      url: `/dashboard/postedjob/lastest?limit=5&offset=0`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static searchUsers(keyWords) {
    return this.request({
      url: `/dashboard/user/search?column=email&keyword=${keyWords}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getWorkplacesSummary() {
    return this.request({
      url: `/dashboard/workplace/count`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getWorkplacesInDashboard(offset, limit) {
    return this.request({
      url: `/workplaces?limit=${limit}&offset=${offset}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getWorkplacesInDashboardWithKeyword(keyword, offset, limit) {
    return this.request({
      url: `/workplaces?limit=${limit}&offset=${offset}&keyword=${keyword}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getStudent(offset, limit, keyword) {
    return this.request({
      url: `/dashboard/user/search?limit=${limit}&role=student&offset=${offset}&keyword=${keyword}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getProfessor(offset, limit, keyword) {
    return this.request({
      url: `/dashboard/user/search?limit=${limit}&role=professor&offset=${offset}&keyword=${keyword}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static getAdmin(offset, limit, keyword) {
    return this.request({
      url: `/dashboard/user/search?limit=${limit}&role=admin&offset=${offset}&keyword=${keyword}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static editUserRole(data) {
    return this.request({
      url: `/dashboard/user/role`,
      method: "PUT",
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }

  static addUser(data) {
    return this.request({
      url: `/dashboard/user`,
      method: "POST",
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tokenId")}`,
      },
    })
  }
}
