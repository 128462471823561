import axios from 'axios'

export default class GoogleApi {
  static getToken(code) {
    return axios({
      method: 'POST',
      url: 'https://www.googleapis.com/oauth2/v4/token',
      headers: { 'Content-Type': 'application/json' },
      data: {
        code: code,
        client_id: process.env.GOOGLE_AUTH_CLIENT_ID,
        client_secret: process.env.GOOGLE_AUTH_CLIENT_SECRET,
        grant_type: 'authorization_code',
        redirect_uri: process.env.WEBSITE_URL
      }
    })
  }

  static refreshToken(refreshToken) {
    return axios({
      method: 'POST',
      url: 'https://www.googleapis.com/oauth2/v4/token',
      headers: { 'Content-Type': 'application/json' },
      data: {
        refresh_token: refreshToken,
        client_id: process.env.GOOGLE_AUTH_CLIENT_ID,
        client_secret: process.env.GOOGLE_AUTH_CLIENT_SECRET,
        grant_type: 'refresh_token'
      }
    })
  }

  static getTokenInfo(tokenId) {
    return axios({
      method: 'GET',
      url: `https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${tokenId}`
    })
  }
}