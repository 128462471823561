const primaryPalette = {
  'primary': '#ff7c1b',
  'primary50 ':'#ffefe4',
  'primary100': '#ffd8bb',
  'primary200': '#ffbe8d',
  'primary300': '#ffa35f',
  'primary400': '#ff903d',
  'primary500': '#ff7c1b',
  'primary600': '#ff7418',
  'primary700': '#ff6914',
  'primary800': '#ff5f10',
  'primary900': '#ff4c08',
}

const theme = {
  'breakpoints': {
    'small': 320,
    'medium': 600,
    'large': 1136
  },
  'colors': {
    ...primaryPalette,
    'accent': '#276EF1',
    'accent50': '#EEF3FE',
    'accent100': '#D4E2FC',
    'accent200': '#A0BFF9',
    'accent300': '#5B91F4',
    'accent400': '#276EF1',
    'accent500': '#1E54B7',
    'accent600': '#174291',
    'accent700': '#102C60',
    'negative': '#D44333',
    'negative50': '#FDF0EF',
    'negative100': '#FADBD7',
    'negative200': '#F4AFA7',
    'negative300': '#EB7567',
    'negative400': '#D44333',
    'negative500': '#AE372A',
    'negative600': '#892C21',
    'negative700': '#5C1D16',
    'warning': '#ED6E33',
    'warning50': '#FEF3EF',
    'warning100': '#FBE2D6',
    'warning200': '#F7BFA5',
    'warning300': '#F19164',
    'warning400': '#ED6E33',
    'warning500': '#B45427',
    'warning600': '#8E421F',
    'warning700': '#5F2C14',
    'positive': '#3AA76D',
    'positive50': '#F0F9F4',
    'positive100': '#DAF0E3',
    'positive200': '#AEDDC2',
    'positive300': '#73C496',
    'positive400': '#3AA76D',
    'positive500': '#368759',
    'positive600': '#2B6B46',
    'positive700': '#1C472F',
    'white': '#FFFFFF',
    'mono100': '#FFFFFF',
    'mono200': '#F6F6F6',
    'mono300': '#EEEEEE',
    'mono400': '#E2E2E2',
    'mono500': '#CBCBCB',
    'mono600': '#AFAFAF',
    'mono700': '#757575',
    'mono800': '#545454',
    'mono900': '#333333',
    'mono1000': '#000000',
    'black': '#000000',
    'rating200': '#FFE3AC',
    'rating400': '#FFC043',
    'colorPrimary': '#000000',
    'colorSecondary': '#545454',
    'background': '#FFFFFF',
    'backgroundAlt': '#FFFFFF',
    'backgroundInv': '#000000',
    'foreground': '#000000',
    'foregroundAlt': '#545454',
    'foregroundInv': '#FFFFFF',
    'border': '#CBCBCB',
    'borderAlt': '#AFAFAF',
    'borderFocus': primaryPalette.primary,
    'borderError': '#D44333',
    'buttonPrimaryFill': primaryPalette.primary,
    'buttonPrimaryText': '#FFFFFF',
    'buttonPrimaryHover': primaryPalette.primary300,
    'buttonPrimaryActive': primaryPalette.primary100,
    'buttonPrimarySelectedFill': primaryPalette.primary,
    'buttonPrimarySelectedText': '#FFFFFF',
    'buttonPrimarySpinnerForeground': '#FFFFFF',
    'buttonPrimarySpinnerBackground': primaryPalette.primary200,
    'buttonSecondaryFill': '#EEEEEE',
    'buttonSecondaryText': primaryPalette.primary,
    'buttonSecondaryHover': '#E2E2E2',
    'buttonSecondaryActive': '#CBCBCB',
    'buttonSecondarySelectedFill': '#E2E2E2',
    'buttonSecondarySelectedText': '#000000',
    'buttonSecondarySpinnerForeground': '#333333',
    'buttonSecondarySpinnerBackground': '#CBCBCB',
    'buttonTertiaryFill': 'transparent',
    'buttonTertiaryText': '#000000',
    'buttonTertiaryHover': '#F6F6F6',
    'buttonTertiaryActive': '#EEEEEE',
    'buttonTertiarySelectedFill': '#EEEEEE',
    'buttonTertiarySelectedText': '#000000',
    'buttonTertiarySpinnerForeground': '#333333',
    'buttonTertiarySpinnerBackground': '#CBCBCB',
    'buttonMinimalFill': 'transparent',
    'buttonMinimalText': '#000000',
    'buttonMinimalHover': '#F6F6F6',
    'buttonMinimalActive': '#EEEEEE',
    'buttonMinimalSelectedFill': '#EEEEEE',
    'buttonMinimalSelectedText': '#000000',
    'buttonMinimalSpinnerForeground': '#333333',
    'buttonMinimalSpinnerBackground': '#CBCBCB',
    'buttonDisabledFill': '#F6F6F6',
    'buttonDisabledText': '#AFAFAF',
    'buttonDisabledSpinnerForeground': '#AFAFAF',
    'buttonDisabledSpinnerBackground': '#E2E2E2',
    'breadcrumbsText': '#333333',
    'breadcrumbsSeparatorFill': '#757575',
    'datepickerBackground': '#FFFFFF',
    'datepickerDayFont': '#000000',
    'datepickerDayFontDisabled': '#CBCBCB',
    'datepickerDayPseudoSelected': '#EEEEEE',
    'datepickerDayPseudoHighlighted': '#E2E2E2',
    'calendarBackground': '#FFFFFF',
    'calendarForeground': primaryPalette.primary,
    'calendarForegroundDisabled': '#CBCBCB',
    'calendarHeaderBackground': primaryPalette.primary,
    'calendarHeaderForeground': '#FFFFFF',
    'calendarHeaderBackgroundActive': '#333333',
    'calendarHeaderForegroundDisabled': '#757575',
    'calendarDayBackgroundPseudoSelected': '#EEEEEE',
    'calendarDayForegroundPseudoSelected': primaryPalette.primary,
    'calendarDayBackgroundPseudoSelectedHighlighted': '#E2E2E2',
    'calendarDayForegroundPseudoSelectedHighlighted': primaryPalette.primary,
    'calendarDayBackgroundSelected': '#FFFFFF',
    'calendarDayForegroundSelected': primaryPalette.primary,
    'calendarDayBackgroundSelectedHighlighted': primaryPalette.primary,
    'calendarDayForegroundSelectedHighlighted': '#FFFFFF',
    'fileUploaderBackgroundColor': '#F6F6F6',
    'fileUploaderBackgroundColorActive': '#F6F6F6',
    'fileUploaderBorderColorActive': '#000000',
    'fileUploaderBorderColorDefault': '#CBCBCB',
    'fileUploaderMessageColor': '#AFAFAF',
    'linkText': '#000000',
    'linkVisited': '#333333',
    'linkHover': '#545454',
    'linkActive': '#757575',
    'shadowFocus': 'rgba(39, 110, 241, 0.32)',
    'shadowError': 'rgba(229, 73, 55, 0.32)',
    'listHeaderFill': '#FFFFFF',
    'listBodyFill': '#F6F6F6',
    'listIconFill': '#CBCBCB',
    'listBorder': '#CBCBCB',
    'progressStepsCompletedText': '#FFFFFF',
    'progressStepsCompletedFill': '#000000',
    'progressStepsActiveText': '#FFFFFF',
    'progressStepsActiveFill': '#000000',
    'progressStepsIconActiveFill': '#000000',
    'tickFill': '#FFFFFF',
    'tickFillHover': '#F6F6F6',
    'tickFillActive': '#EEEEEE',
    'tickFillSelected': primaryPalette.primary,
    'tickFillSelectedHover': primaryPalette.primary300,
    'tickFillSelectedHoverActive': '#ffd8bb',
    'tickFillError': '#FDF0EF',
    'tickFillErrorHover': '#FADBD7',
    'tickFillErrorHoverActive': '#F4AFA7',
    'tickFillErrorSelected': '#D44333',
    'tickFillErrorSelectedHover': '#AE372A',
    'tickFillErrorSelectedHoverActive': '#892C21',
    'tickFillDisabled': '#EEEEEE',
    'tickBorder': '#757575',
    'tickBorderError': '#D44333',
    'tickMarkFill': '#FFFFFF',
    'tickMarkFillError': '#FFFFFF',
    'tickMarkFillDisabled': '#AFAFAF',
    'sliderTrackFill': '#000000',
    'sliderTrackFillHover': '#CBCBCB',
    'sliderTrackFillActive': '#AFAFAF',
    'sliderTrackFillSelected': '#000000',
    'sliderTrackFillSelectedHover': '#000000',
    'sliderTrackFillSelectedActive': '#757575',
    'sliderTrackFillDisabled': '#EEEEEE',
    'sliderHandleFill': '#FFFFFF',
    'sliderHandleFillHover': '#FFFFFF',
    'sliderHandleFillActive': '#FFFFFF',
    'sliderHandleFillSelected': '#FFFFFF',
    'sliderHandleFillSelectedHover': '#FFFFFF',
    'sliderHandleFillSelectedActive': '#FFFFFF',
    'sliderHandleFillDisabled': '#CBCBCB',
    'sliderHandleInnerFill': '#E2E2E2',
    'sliderHandleInnerFillDisabled': '#E2E2E2',
    'sliderHandleInnerFillSelectedHover': '#000000',
    'sliderHandleInnerFillSelectedActive': '#757575',
    'sliderBorder': '#CBCBCB',
    'sliderBorderHover': '#000000',
    'sliderBorderDisabled': '#AFAFAF',
    'inputFill': '#EEEEEE',
    'inputFillError': '#FDF0EF',
    'inputFillDisabled': '#F6F6F6',
    'inputFillActive': '#F6F6F6',
    'inputFillPositive': '#F0F9F4',
    'inputTextDisabled': '#AFAFAF',
    'inputBorderError': '#F4AFA7',
    'inputBorderPositive': '#AEDDC2',
    'inputEnhancerFill': '#EEEEEE',
    'inputEnhancerFillDisabled': '#EEEEEE',
    'inputEnhancerTextDisabled': '#AFAFAF',
    'menuFill': '#FFFFFF',
    'menuFillHover': '#F6F6F6',
    'menuFontDefault': '#545454',
    'menuFontDisabled': '#CBCBCB',
    'menuFontHighlighted': '#000000',
    'menuFontSelected': '#000000',
    'modalCloseColor': '#000000',
    'modalCloseColorHover': '#545454',
    'modalCloseColorFocus': '#545454',
    'paginationTriangleDown': '#545454',
    'headerNavigationFill': 'transparent',
    'tabBarFill': 'white',
    'tabColor': '#545454',
    'notificationPrimaryBackground': '#F6F6F6',
    'notificationPrimaryText': '#757575',
    'notificationInfoBackground': '#EEF3FE',
    'notificationInfoText': '#1E54B7',
    'notificationPositiveBackground': '#F0F9F4',
    'notificationPositiveText': '#368759',
    'notificationWarningBackground': '#FEF3EF',
    'notificationWarningText': '#B45427',
    'notificationNegativeBackground': '#FDF0EF',
    'notificationNegativeText': '#AE372A',
    'tagSolidRampUnit': '400',
    'tagSolidHoverRampUnit': '50',
    'tagSolidActiveRampUnit': '100',
    'tagSolidDisabledRampUnit': '50',
    'tagSolidFontRampUnit': '50',
    'tagSolidFontHoverRampUnit': '500',
    'tagLightRampUnit': '50',
    'tagLightHoverRampUnit': '100',
    'tagLightActiveRampUnit': '100',
    'tagLightDisabledRampUnit': '50',
    'tagLightFontRampUnit': '500',
    'tagLightFontHoverRampUnit': '500',
    'tagOutlinedRampUnit': '400',
    'tagOutlinedHoverRampUnit': '500',
    'tagOutlinedActiveRampUnit': '600',
    'tagOutlinedDisabledRampUnit': '50',
    'tagOutlinedFontRampUnit': '500',
    'tagOutlinedFontHoverRampUnit': '50',
    'tagFontDisabledRampUnit': '200',
    'tagNeutralSolidBackground': '#333333',
    'tagNeutralSolidHover': '#EEEEEE',
    'tagNeutralSolidActive': '#E2E2E2',
    'tagNeutralSolidDisabled': '#F6F6F6',
    'tagNeutralSolidFont': '#FFFFFF',
    'tagNeutralSolidFontHover': '#333333',
    'tagNeutralLightBackground': '#EEEEEE',
    'tagNeutralLightHover': '#EEEEEE',
    'tagNeutralLightActive': '#E2E2E2',
    'tagNeutralLightDisabled': '#F6F6F6',
    'tagNeutralLightFont': '#333333',
    'tagNeutralLightFontHover': '#333333',
    'tagNeutralOutlinedBackground': '#333333',
    'tagNeutralOutlinedHover': '#545454',
    'tagNeutralOutlinedActive': '#333333',
    'tagNeutralOutlinedDisabled': '#F6F6F6',
    'tagNeutralOutlinedFont': '#333333',
    'tagNeutralOutlinedFontHover': '#F6F6F6',
    'tagNeutralFontDisabled': '#CBCBCB',
    'tagPrimarySolidBackground': '#FF7C1B',
    'tagPrimarySolidHover': '#EEEEEE',
    'tagPrimarySolidActive': '#E2E2E2',
    'tagPrimarySolidDisabled': '#F6F6F6',
    'tagPrimarySolidFont': '#F6F6F6',
    'tagPrimarySolidFontHover': '#333333',
    'tagPrimaryLightBackground': '#F6F6F6',
    'tagPrimaryLightHover': '#EEEEEE',
    'tagPrimaryLightActive': '#EEEEEE',
    'tagPrimaryLightDisabled': '#F6F6F6',
    'tagPrimaryLightFont': '#757575',
    'tagPrimaryLightFontHover': '#757575',
    'tagPrimaryOutlinedBackground': primaryPalette.primary,
    'tagPrimaryOutlinedHover': '#333333',
    'tagPrimaryOutlinedActive': '#545454',
    'tagPrimaryOutlinedDisabled': '#F6F6F6',
    'tagPrimaryOutlinedFont': primaryPalette.primary,
    'tagPrimaryOutlinedFontHover': '#F6F6F6',
    'tagPrimaryFontDisabled': '#AFAFAF',
    'tagAccentSolidBackground': '#276EF1',
    'tagAccentSolidHover': '#EEF3FE',
    'tagAccentSolidActive': '#D4E2FC',
    'tagAccentSolidDisabled': '#EEF3FE',
    'tagAccentSolidFont': '#EEF3FE',
    'tagAccentSolidFontHover': '#1E54B7',
    'tagAccentLightBackground': '#EEF3FE',
    'tagAccentLightHover': '#D4E2FC',
    'tagAccentLightActive': '#D4E2FC',
    'tagAccentLightDisabled': '#EEF3FE',
    'tagAccentLightFont': '#1E54B7',
    'tagAccentLightFontHover': '#1E54B7',
    'tagAccentOutlinedBackground': '#276EF1',
    'tagAccentOutlinedHover': '#1E54B7',
    'tagAccentOutlinedActive': '#174291',
    'tagAccentOutlinedDisabled': '#EEF3FE',
    'tagAccentOutlinedFont': '#1E54B7',
    'tagAccentOutlinedFontHover': '#EEF3FE',
    'tagAccentFontDisabled': '#A0BFF9',
    'tagPositiveSolidBackground': '#3AA76D',
    'tagPositiveSolidHover': '#F0F9F4',
    'tagPositiveSolidActive': '#DAF0E3',
    'tagPositiveSolidDisabled': '#F0F9F4',
    'tagPositiveSolidFont': '#F0F9F4',
    'tagPositiveSolidFontHover': '#368759',
    'tagPositiveLightBackground': '#F0F9F4',
    'tagPositiveLightHover': '#DAF0E3',
    'tagPositiveLightActive': '#DAF0E3',
    'tagPositiveLightDisabled': '#F0F9F4',
    'tagPositiveLightFont': '#368759',
    'tagPositiveLightFontHover': '#368759',
    'tagPositiveOutlinedBackground': '#3AA76D',
    'tagPositiveOutlinedHover': '#368759',
    'tagPositiveOutlinedActive': '#2B6B46',
    'tagPositiveOutlinedDisabled': '#F0F9F4',
    'tagPositiveOutlinedFont': '#368759',
    'tagPositiveOutlinedFontHover': '#F0F9F4',
    'tagPositiveFontDisabled': '#AEDDC2',
    'tagWarningSolidBackground': '#ED6E33',
    'tagWarningSolidHover': '#FEF3EF',
    'tagWarningSolidActive': '#FBE2D6',
    'tagWarningSolidDisabled': '#FEF3EF',
    'tagWarningSolidFont': '#FEF3EF',
    'tagWarningSolidFontHover': '#B45427',
    'tagWarningLightBackground': '#FEF3EF',
    'tagWarningLightHover': '#FBE2D6',
    'tagWarningLightActive': '#FBE2D6',
    'tagWarningLightDisabled': '#FEF3EF',
    'tagWarningLightFont': '#B45427',
    'tagWarningLightFontHover': '#B45427',
    'tagWarningOutlinedBackground': '#ED6E33',
    'tagWarningOutlinedHover': '#B45427',
    'tagWarningOutlinedActive': '#8E421F',
    'tagWarningOutlinedDisabled': '#FEF3EF',
    'tagWarningOutlinedFont': '#B45427',
    'tagWarningOutlinedFontHover': '#FEF3EF',
    'tagWarningFontDisabled': '#F7BFA5',
    'tagNegativeSolidBackground': '#D44333',
    'tagNegativeSolidHover': '#FDF0EF',
    'tagNegativeSolidActive': '#FADBD7',
    'tagNegativeSolidDisabled': '#FDF0EF',
    'tagNegativeSolidFont': '#FDF0EF',
    'tagNegativeSolidFontHover': '#AE372A',
    'tagNegativeLightBackground': '#FDF0EF',
    'tagNegativeLightHover': '#FADBD7',
    'tagNegativeLightActive': '#FADBD7',
    'tagNegativeLightDisabled': '#FDF0EF',
    'tagNegativeLightFont': '#AE372A',
    'tagNegativeLightFontHover': '#AE372A',
    'tagNegativeOutlinedBackground': '#D44333',
    'tagNegativeOutlinedHover': '#AE372A',
    'tagNegativeOutlinedActive': '#892C21',
    'tagNegativeOutlinedDisabled': '#FDF0EF',
    'tagNegativeOutlinedFont': '#AE372A',
    'tagNegativeOutlinedFontHover': '#FDF0EF',
    'tagNegativeFontDisabled': '#F4AFA7',
    'tableHeadBackgroundColor': '#FFFFFF',
    'tableBackground': '#FFFFFF',
    'tableStripedBackground': '#F6F6F6',
    'tableFilter': '#AFAFAF',
    'tableFilterHeading': '#757575',
    'tableFilterBackground': '#FFFFFF',
    'tableFilterFooterBackground': '#F6F6F6',
    'toastText': '#FFFFFF',
    'toastPrimaryBackground': '#757575',
    'toastInfoBackground': '#1E54B7',
    'toastPositiveBackground': '#368759',
    'toastWarningBackground': '#B45427',
    'toastNegativeBackground': '#AE372A',
    'spinnerTrackFill': '#333333',
    'progressbarTrackFill': '#333333',
    'tooltipBackground': '#333333',
    'tooltipText': '#FFFFFF'
  },
  'typography': {
    'font100': {
      'fontFamily': 'Prompt, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
      'fontSize': '12px',
      'fontWeight': 'normal',
      'lineHeight': '20px'
    },
    'font150': {
      'fontFamily': 'Prompt, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
      'fontSize': '12px',
      'fontWeight': '500',
      'lineHeight': '20px'
    },
    'font200': {
      'fontFamily': 'Prompt, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
      'fontSize': '14px',
      'fontWeight': 'normal',
      'lineHeight': '20px'
    },
    'font250': {
      'fontFamily': 'Prompt, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
      'fontSize': '14px',
      'fontWeight': 500,
      'lineHeight': '20px'
    },
    'font300': {
      'fontFamily': 'Prompt, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
      'fontSize': '16px',
      'fontWeight': 'normal',
      'lineHeight': '24px'
    },
    'font350': {
      'fontFamily': 'Prompt, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
      'fontSize': '16px',
      'fontWeight': 500,
      'lineHeight': '24px'
    },
    'font400': {
      'fontFamily': 'Prompt, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
      'fontSize': '18px',
      'fontWeight': 'normal',
      'lineHeight': '28px'
    },
    'font450': {
      'fontFamily': 'Prompt, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
      'fontSize': '18px',
      'fontWeight': 500,
      'lineHeight': '28px'
    },
    'font550': {
      'fontFamily': 'Prompt, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
      'fontSize': '20px',
      'fontWeight': 800,
      'lineHeight': '28px'
    },
    'font650': {
      'fontFamily': 'Prompt, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
      'fontSize': '24px',
      'fontWeight': 500,
      'lineHeight': '32px'
    },
    'font750': {
      'fontFamily': 'Prompt, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
      'fontSize': '28px',
      'fontWeight': 500,
      'lineHeight': '36px'
    },
    'font850': {
      'fontFamily': 'Prompt, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
      'fontSize': '32px',
      'fontWeight': 500,
      'lineHeight': '40px'
    },
    'font950': {
      'fontFamily': 'Prompt, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
      'fontSize': '36px',
      'fontWeight': 500,
      'lineHeight': '44px'
    },
    'font1050': {
      'fontFamily': 'Prompt, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
      'fontSize': '40px',
      'fontWeight': 500,
      'lineHeight': '52px'
    },
    'font1150': {
      'fontFamily': 'Prompt, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
      'fontSize': '36px',
      'fontWeight': 500,
      'lineHeight': '44px'
    },
    'font1250': {
      'fontFamily': 'Prompt, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
      'fontSize': '44px',
      'fontWeight': 500,
      'lineHeight': '52px'
    },
    'font1350': {
      'fontFamily': 'Prompt, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
      'fontSize': '52px',
      'fontWeight': 500,
      'lineHeight': '64px'
    },
    'font1450': {
      'fontFamily': 'Prompt, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
      'fontSize': '96px',
      'fontWeight': 500,
      'lineHeight': '112px'
    }
  },
  'sizing': {
    'scale0': '2px',
    'scale100': '4px',
    'scale200': '6px',
    'scale300': '8px',
    'scale400': '10px',
    'scale500': '12px',
    'scale550': '14px',
    'scale600': '16px',
    'scale650': '18px',
    'scale700': '20px',
    'scale750': '22px',
    'scale800': '24px',
    'scale900': '32px',
    'scale1000': '40px',
    'scale1200': '48px',
    'scale1400': '56px',
    'scale1600': '64px',
    'scale2400': '96px',
    'scale3200': '128px',
    'scale4800': '192px'
  },
  'lighting': {
    'shadow400': '0 1px 4px hsla(0, 0%, 0%, 0.16)',
    'shadow500': '0 2px 8px hsla(0, 0%, 0%, 0.16)',
    'shadow600': '0 4px 16px hsla(0, 0%, 0%, 0.16)',
    'shadow700': '0 8px 24px hsla(0, 0%, 0%, 0.16)',
    'overlay0': 'inset 0 0 0 1000px hsla(0, 0%, 0%, 0)',
    'overlay100': 'inset 0 0 0 1000px hsla(0, 0%, 0%, 0.04)',
    'overlay200': 'inset 0 0 0 1000px hsla(0, 0%, 0%, 0.08)',
    'overlay300': 'inset 0 0 0 1000px hsla(0, 0%, 0%, 0.12)',
    'overlay400': 'inset 0 0 0 1000px hsla(0, 0%, 0%, 0.16)',
    'overlay500': 'inset 0 0 0 1000px hsla(0, 0%, 0%, 0.2)',
    'overlay600': 'inset 0 0 0 1000px hsla(0, 0%, 0%, 0.24)'
  },
  'borders': {
    'border100': {
      'borderColor': 'hsla(0, 0%, 0%, 0.04)',
      'borderStyle': 'solid',
      'borderWidth': '1px'
    },
    'border200': {
      'borderColor': 'hsla(0, 0%, 0%, 0.08)',
      'borderStyle': 'solid',
      'borderWidth': '1px'
    },
    'border300': {
      'borderColor': 'hsla(0, 0%, 0%, 0.12)',
      'borderStyle': 'solid',
      'borderWidth': '1px'
    },
    'border400': {
      'borderColor': 'hsla(0, 0%, 0%, 0.16)',
      'borderStyle': 'solid',
      'borderWidth': '1px'
    },
    'border500': {
      'borderColor': 'hsla(0, 0%, 0%, 0.2)',
      'borderStyle': 'solid',
      'borderWidth': '1px'
    },
    'border600': {
      'borderColor': 'hsla(0, 0%, 0%, 0.24)',
      'borderStyle': 'solid',
      'borderWidth': '1px'
    },
    'radius100': '2px',
    'radius200': '4px',
    'radius300': '8px',
    'radius400': '12px',
    'useRoundedCorners': true,
    'buttonBorderRadius': '8px',
    'inputBorderRadius': '0px',
    'popoverBorderRadius': '0px',
    'surfaceBorderRadius': '0px'
  },
  'animation': {
    'timing100': '0.25s',
    'timing400': '0.4s',
    'timing700': '0.6s',
    'easeOutCurve': 'cubic-bezier(.2, .8, .4, 1)',
    'easeInCurve': 'cubic-bezier(.8, .2, .6, 1)',
    'easeInOutCurve': 'cubic-bezier(0.4, 0, 0.2, 1)'
  },
  'direction': 'auto',
  'zIndex': {
    'modal': 2000
  },
  'name': 'kmitl-theme'
}

export default theme
