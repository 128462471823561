import CloudApi from '../cloudApi/api'
import googleApi from '../cloudApi/googleApi'

export const getProfile = (dispatch) => {
  return CloudApi.getProfile().then((data) => {
    dispatch({
      type: 'FETCH_USER_DATA',
      payload: {
        ...data.backend,
        ...data.reg_kmitl
      }
    })
  }).catch((err) => {
    if (err && (err.status === 401)) {
      const refreshToken = localStorage.getItem('refreshToken')
      return googleApi.refreshToken(refreshToken).then(({ data }) => {
        localStorage.setItem('tokenId', data.id_token)
        getProfile(dispatch)
      })
    } else if (err && (err.status === 404)) {
      localStorage.removeItem('tokenId')
      localStorage.removeItem('refreshToken')
    }
  })
}
