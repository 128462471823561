import unionBy from "lodash/unionBy"

const initialState = {
  jobs: [],
  tags: [],
  departments: [],
  appliedJobs: [],
  prices: null,
  jobTotal: 0,
  shouldGetMoreJobs: true,
}

function JobsReducer(state, action) {
  switch (action.type) {
    case "FETCH_JOBS_DATA":
      return {
        ...state,
        jobs: action.reset
          ? action.payload.jobs
          : unionBy(action.payload.jobs, state.jobs, "id").sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            ),
        shouldGetMoreJobs: action.payload.jobs.length > 0,
        jobTotal: action.payload.total_job,
      }
    case "FETCH_TAGS_DATA":
      return { ...state, tags: action.payload }
    case "FETCH_DEPARTMENTS_DATA":
      return { ...state, departments: action.payload }
    case "FETCH_JOBS_DETAIL":
      return {
        ...state,
        jobs:
          state.jobs === 0
            ? state.jobs.push(action.payload)
            : state.jobs.map(job =>
                job.id === action.payload.id ? action.payload : job
              ),
      }
    case "FETCH_APPLIED_JOBS_DATA":
      return { ...state, appliedJobs: action.payload.jobs }
    case "FETCH_JOBS_PRICES":
      return { ...state, prices: action.payload }
    case "CLEAR_APPLIED_JOBS_DATA":
      return { ...state, appliedJobs: [] }
    case "NO_FILTER_JOB":
      return { ...state, jobs: [] }
    case "STOP_FETCH_JOBS":
      return { ...state, shouldGetMoreJobs: false }
    default:
      return state
  }
}

export default { initialState, JobsReducer }
